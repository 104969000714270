import { useCallback } from "react";
import { castPromiseToItself } from "@planda/design-system";
import { fetcher } from "@/lib/fetch";
import { useAuthSWR } from "@/lib/swr";
import { Meeting, UserMeeting } from "@/types";
import { Chat } from "../../types/message";

export type MeetingWithUser = Meeting & { userSettings?: UserMeeting }

const useChatList = (userId: string | undefined, onSuccess: (data: MeetingWithUser[]) => void) => {
    const { data: meetings, mutate, isLoading } = useAuthSWR<MeetingWithUser[]>(userId, '/api/user/meetings', {
        onSuccess: (data) => {
            userId && onSuccess(data);
        }
    });

    const addChat = useCallback(async (chat: Chat, promise: Promise<unknown>) => {
        if (!userId) return;
        mutate(castPromiseToItself(promise, `/api/user/meetings?userid=${userId}`), {
            optimisticData: (meetings) => [...(meetings || []), { ...chat, name: 'No name' }]
        });
    }, [userId])

    const removeChat = (chat: Chat) => {
        userId && fetcher(`/api/meeting/${chat.id}`, 'DELETE', undefined, { userid: userId })
        mutate(meetings?.filter((c) => c.id !== chat.id));
    }

    return {
        chatList: meetings,
        addChat,
        removeChat,
        isLoading
    }
}
export default useChatList
