'use client';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { v4 as uuid } from 'uuid';
import { uploadFiles } from './useChatHook';
import { Persona } from '../../types/message';

const usePersonaHook = () => {
    const [personas, setPersonas] = useState<Persona[]>([]);

    const [editPersona, setEditPersona] = useState<Persona | undefined>();

    const [isOpenPersonaModal, setIsOpenPersonaModal] = useState<boolean>(false);

    const [personaModalLoading, setPersonaModalLoading] = useState<boolean>(false);

    const onCreatePersona = async (values: any) => {
        const { type, name, prompt, files } = values;
        const persona: Persona = {
            id: uuid(),
            role: 'system',
            name,
            prompt,
            key: ''
        };

        if (type === 'document') {
            try {
                setPersonaModalLoading(true);
                const data = await uploadFiles(files);
                persona.key = data.key;
            } catch (e) {
                console.log(e);
                toast.error('Error uploading files');
            } finally {
                setPersonaModalLoading(false);
            }
        }

        setPersonas((state) => {
            const index = state.findIndex((item) => item.id === editPersona?.id);
            if (index === -1) {
                state.push(persona);
            } else {
                state.splice(index, 1, persona);
            }
            return [...state];
        });

        onClosePersonaModal();
    };

    const onEditPersona = async (persona: Persona) => {
        setEditPersona(persona);
        onOpenPersonaModal();
    };

    const onDeletePersona = (persona: Persona) => {
        setPersonas((state) => {
            const index = state.findIndex((item) => item.id === persona.id);
            state.splice(index, 1);
            return [...state];
        });
    };

    const onOpenPersonaModal = () => {
        setIsOpenPersonaModal(true);
    };

    const onClosePersonaModal = () => {
        setEditPersona(undefined);
        setIsOpenPersonaModal(false);
    };

    useEffect(() => {
        const loadedPersonas = JSON.parse(localStorage.getItem('Personas') || '[]') as Persona[];
        const updatedPersonas = loadedPersonas.map((persona) => {
            if (!persona.id) {
                persona.id = uuid();
            }
            return persona;
        });
        setPersonas(updatedPersonas);
    }, []);

    useEffect(() => {
        localStorage.setItem('Personas', JSON.stringify(personas));
    }, [personas]);

    return {
        personas,
        editPersona,
        isOpenPersonaModal,
        personaModalLoading,
        onCreatePersona,
        onDeletePersona,
        onEditPersona,
        onOpenPersonaModal,
        onClosePersonaModal
    };

};

export default usePersonaHook;