import { z } from "zod";

// Maps from inviteId to meetingId + joinInfo

export const InviteSchema = z.object({
    id: z.string(), // INVITE#<personalId>
    meetingId: z.string(), // <meetingId>
    isOrganizer: z.boolean().optional(), // default false
    inviteId: z.string().optional(), // <meetingId>-<personalId>
    maxJoin: z.number().optional(),
    joinCount: z.number().default(0),
    targetGroup: z.string().optional(), // ex. friends, staff, volunteers, VIP, etc.
    isPublic: z.boolean().optional(),
})

export type Invite = z.infer<typeof InviteSchema>

export const InviteResponseSchema = z.object({
    meetingId: z.string(),
})