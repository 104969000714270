import { useMeetingSWR } from "@/lib/swr"
import { Falsy, Participant } from "@/types"

export const useParticipant = (meetingId: Falsy<string>, userId?: Falsy<string>) => {
    const { data: participant } = useMeetingSWR<Participant>(meetingId, userId, 'participant')

    return {
        participant,
    }
}
