import { useEffect } from "react";
import { Tooltip, IconButton } from "@radix-ui/themes";
import { HiOutlineMicrophone } from "react-icons/hi";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const Dictaphone = ({ onSpeakEnd }: {
    onSpeakEnd: (message: string | ((message: string) => void)) => void
}) => {
    const { resetTranscript, finalTranscript, listening, browserSupportsSpeechRecognition } = useSpeechRecognition();
    const startListening = () => SpeechRecognition.startListening({ continuous: true });

    useEffect(() => {
        if (finalTranscript !== '') {
            // User has finished speaking, so add speech to the input
            onSpeakEnd(finalTranscript)
            // Create a fresh transcript to avoid the same transcript being appended multiple times
            resetTranscript()
        }
    }, [finalTranscript, resetTranscript]);

    if (!browserSupportsSpeechRecognition) {
        return <></>
    }

    return (
        <Tooltip content={'Clear Message'}>
            <IconButton
                variant="soft"
                color="gray"
                size="2"
                className="rounded-xl cursor-pointer"
                style={listening ? { background: 'var(--black-a12)', color: 'var(--white-a12)' } : undefined}
                onTouchStart={startListening}
                onMouseDown={startListening}
                onTouchEnd={SpeechRecognition.stopListening}
                onMouseUp={SpeechRecognition.stopListening}
            >
                <HiOutlineMicrophone />
            </IconButton>
        </Tooltip>
    );
};
export default Dictaphone;