export enum Mode {
    Available = -1,
    Will_Move_If_Must = 1,
    Unavailable = 2,
    Neutral = 0
}

export const modeToColor = (mode: Mode) => {
    // coupling with drag-calendar/Cell.tsx
    return mode === Mode.Available ? 'var(--grass-9)' : mode === Mode.Unavailable ? 'var(--red-9)' : (
        mode === Mode.Will_Move_If_Must ? 'var(--orange-9)' : 'var(--color-background)'
    )
}
