/**
 * Includes both organizers and attendees
*/
// meet-table
import { z } from "zod"

export const GoingStateEnum = z.enum(["removed", "going"])
export type GoingState = z.infer<typeof GoingStateEnum>

export const ParticipantSchema = z.object({
    meetingId: z.string(), // Meeting.id
    id: z.string(), // PARTICIPANT# + UserSchema.name
    name: z.string(), // exact same as in id, but here for easy access
    // name: z.string(), // default value is UserSchema.name
    userId: z.string(), // same as UserSchema // TODO: make this a gsi or lsi
    state: GoingStateEnum.optional(),
    // organizer or attendee
    isOrganizer: z.boolean(),
    willingnessToReschedule: z.number().optional(),
})

export type Participant = z.infer<typeof ParticipantSchema>

export const NewParticipantSchema = ParticipantSchema.partial({
    userId: true, meetingId: true, id: true, name: true
})

export type NewParticipant = z.infer<typeof NewParticipantSchema>