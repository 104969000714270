'use client';
import { useContext } from 'react';
import MessageBase from './MessageBase';
import { ChatContext } from '..';
import { ChatRole } from '../../../types/message';

/**
 * Show immediately for casual chats
 * Hide until event is ready to publish for event+business chats
 * @param meeetingId
 * @returns
 */


const JoinMessage = () => {
    const { meeting, user } = useContext(ChatContext);

    const person = meeting?.creatorName || 'someone';

    const isCreator = meeting?.creator === user?.userId;

    const props = {
        message: {
            role: 'assistant' as ChatRole,
            content: isCreator ? `You've created a meeting.` : `You've joined ${person}'s meeting.`
        }
    };

    return (
        <MessageBase {...props}>
        </MessageBase>
    );
};

export default JoinMessage;