'use client'
import React, { useEffect, useRef } from 'react'
import { range } from 'lodash'
import styles from './HourLabels.module.css'

/**
 *
 * @param showHourBorder honestly not super sure what this is, something to do with not needing a border for the paper layout, TODO: make this prop unnecessary
 * @returns
 */
const HourLabels = ({ incrementInMinutes, showHourBorder = true, disableScroll = false, hideMeridian = false, startHour, endHour }: {
    showHourBorder?: boolean, disableScroll?: boolean,
    hideMeridian?: boolean, startHour: number, endHour: number,
    incrementInMinutes: number,
}) => {
    const multiplier = Math.round(60 / incrementInMinutes / 2)
    const scrollToHourRef = useRef(null)
    const total = Math.round((endHour - startHour) * multiplier)
    const hourProps = range(total).map(halfHour => {
        halfHour += startHour * multiplier
        const isStartOfHour = halfHour % multiplier === 0
        const hour = (halfHour / multiplier) % 12 || 12
        const meridian = hideMeridian ? "" : " " + (halfHour < total / 2 ? "am" : "pm")
        const label = isStartOfHour ? hour + meridian : ''

        const props = {
            ref: isStartOfHour && 7 === Math.round(halfHour / multiplier) ? scrollToHourRef : null,
            key: "l" + halfHour,
        }

        return { props, label }
    })

    useEffect(() => {
        // TODO: maybe a scroll animation?
        // @ts-expect-error block: "nearest", inline: "nearest",
        !disableScroll && scrollToHourRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }, []);

    return (
        <section className={styles.container} >
            {/* color={showHourBorder} */}
            {hourProps.map((x, i, arr) => {
                const { props, label } = x
                // isLast={showHourBorder && i === arr.length - 1}
                return <label className={styles.label} {...props} key={props.key} >{label}</label>
            })}
        </section>
    )
}

export default HourLabels
