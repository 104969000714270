'use client'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Avatar, Flex, Slider, Popover } from '@radix-ui/themes'
import { debounce } from 'lodash-es'
import { RiRobot2Line } from 'react-icons/ri'
import { ChatContext } from '@/components'
import { fetcher } from '@/lib/fetch'

const content =
  'How willing are you to reschedule in order to attend this meeting. (default is unwilling)'
// with 0 meaning unwilling to reschedule, and 10 meaning will reschedule everything
const WillingnessToRescheduleMessage = ({}: {}) => {
  const { user, meeting, participant } = useContext(ChatContext)
  const [willingnessValue, setWillingnessValue] = useState(
    participant?.willingnessToReschedule || 0
  )
  // const { } =;Als
  // const { data: participant, mutate } = useAuthSWR<{ willingnessToReschedule?: number }>(user?.userId, id ? `/api/meeting/${id}/participant` : null, {
  //   onSuccess: (data) => {
  //     console.log("DATA participan", data)
  //     setWillingnessValue(data.willingnessToReschedule || 0)
  //   }
  // })

  useEffect(() => {
    if (participant) {
      setWillingnessValue(participant.willingnessToReschedule || 0)
    }
  }, [participant])

  // console.log("participant WillingnessToRescheduleMessage", participant)

  const handleSave = useCallback(
    debounce((value: number) => {
      setWillingnessValue(value)
      return fetcher(
        `/api/meeting/${meeting?.meetingId}/participant`,
        'PATCH',
        {
          set: {
            willingnessToReschedule: value
          }
        },
        { userid: user?.userId }
      )
    }, 350),
    [meeting?.meetingId]
  )

  return (
    <Flex gap="4" className="mb-5">
      <Popover.Root>
        <Popover.Trigger>
          <div>
            <Avatar
              fallback={<RiRobot2Line className="size-4" />}
              color={'green'}
              size="2"
              radius="full"
            />
          </div>
        </Popover.Trigger>
        <Popover.Content side="top" sideOffset={7} align="center">
          <div>{'Spire'}</div>
        </Popover.Content>
      </Popover.Root>
      <div className="flex-1 pt-1 break-word">
        <Flex direction="column" gap="4">
          <p>{content}</p>
          <Flex wrap={'wrap'} className="px-2" gap="4" align="center">
            <Slider
              min={0}
              max={10}
              step={1}
              value={[willingnessValue]}
              onValueChange={(value) => {
                setWillingnessValue(value[0])
                handleSave(value[0])
              }}
              className="w-full"
            />
          </Flex>
        </Flex>
      </div>
    </Flex>
  )
}

export default WillingnessToRescheduleMessage
