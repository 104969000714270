import React from 'react'
import { CaretLeftIcon, CaretRightIcon } from '@radix-ui/react-icons'

const CaretNav = ({
    value,
    // onLeftClick,
    // onRightClick,
    // current,
    setValue,
    total
}: {
    value: number,
    setValue: (value: number) => void,
    // onLeftClick?: () => void,
    // onRightClick?: () => void,
    // current: number,
    total: number,
}) => {
    const onLeftClick = () => {
        value > 0 && setValue(value - 1)
    }
    const onRightClick = () => {
        value < total - 1 && setValue(value + 1)
    }
    return (
        <div className="text-xs flex items-center justify-center gap-1 self-center visible">
            <button disabled={value === 0} onClick={onLeftClick} className="bg-transparent dark:text-white disabled:text-gray-300 dark:disabled:text-gray-400">
                <CaretLeftIcon />
            </button>
            <span className="grow shrink-0 tabular-nums">{Math.round(value + 1)} / {total}</span>
            <button onClick={onRightClick} className="bg-transparent dark:text-white disabled:text-gray-300 dark:disabled:text-gray-400">
                <CaretRightIcon />
            </button>
        </div>
    )
}

export default CaretNav