import React, { ReactNode } from 'react'
import styles from './Cell.module.css'
import { Mode, modeToColor } from './utils'

const Cell = ({
    availability,
    isOver,
    isAlmostStartOfHour,
    isAlmostMiddleOfHour,
    // isSameDayOfWeek,
    children,
    date,
}: {
    availability: Mode,
    isOver?: boolean,
    isAlmostStartOfHour: boolean,
    isAlmostMiddleOfHour: boolean,
    // isSameDayOfWeek: boolean,
    children?: ReactNode,
    date: number,
}) => {
    const dateObj = new Date(date)
    const borderBottom = `1px ${isAlmostStartOfHour ? 'solid' : isAlmostMiddleOfHour ? 'dashed' : 'none'} ${(isAlmostStartOfHour || isAlmostMiddleOfHour) ? 'black' : 'transparent'}`
    const backgroundColor = isOver ? 'var(--accent-a3)' : modeToColor(availability)
    return (
        <div className={styles.cell}
            style={{
                backgroundColor: (availability === Mode.Neutral && (dateObj.getHours() < 7 || dateObj.getHours() >= 21 || date < Date.now())) ? 'var(--gray-a1)' : backgroundColor,
                // borderBottom: '1px solid transparent',
                // borderBottomStyle: isAlmostStartOfHour ? 'solid' : isAlmostMiddleOfHour ? 'dashed' : undefined,
                // borderBottomColor: (isAlmostStartOfHour || isAlmostMiddleOfHour) ? 'black' : 'transparent',
                borderBottom,
            }}
        >
            {children}
        </div>
    )
}

export default Cell