import React from 'react'
import { addDays, format } from 'date-fns'
import { range } from 'lodash'
// import { WIDTH_OF_HOUR_LABELS } from '../../constants'
import styles from './DayOfWeekLabels.module.css'
const DayOfWeekLabels = ({ firstDay, days, weekday = 'long' }: { firstDay: Date | number, days: number, weekday?: "long" | "short" | "narrow" }) => {
    const dayOfWeekLabelProps = range(days).map(day => { // day is 0 - 6 i think?
        const dayOfWeekDay = addDays(firstDay, day)
        const label = dayOfWeekDay.toLocaleString(undefined, { weekday: weekday })
        return { dayOfWeek: dayOfWeekDay.getDay(), label, date: dayOfWeekDay }
    })

    return (
        <div className={styles.container}>
            {dayOfWeekLabelProps.map(x => (
                <label className={styles.label}
                    style={new Date().getDay() === x.dayOfWeek ? {
                        color: 'var(--accent-11)',
                    } : undefined}
                    key={x.dayOfWeek}>{x.label}<sub style={{ marginLeft: 5 }}>{format(x.date, "d")}</sub></label>))}
        </div>
    )
}

export default DayOfWeekLabels
