import { roundToNearestMinutes } from "date-fns"

export const INCREMENT_BY_MINUTES = 15

export const MEETING_ID_SIZE = 21

export const roundMinutes = (date: Date | number, roundingMethod = 'round' as 'round' | 'floor' | 'ceil') => {
    return roundToNearestMinutes(date, { nearestTo: INCREMENT_BY_MINUTES, roundingMethod }).getTime()
}

export const EMAIL_RESPONSE_MESSAGE = `Here are the emails I've drafted up for you:`
export const SINGLE_EMAIL_RESPONSE_MESSAGE = `Here is the email I've drafted up for you:`

export const REGULAR_WAKE_UP_TIME = 9
export const REGULAR_SLEEP_TIME = 23