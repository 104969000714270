'use client'
import { ReactNode, useState } from 'react'
import { Flex } from '@radix-ui/themes'
import { Markdown } from '@/components'
import CaretNav from '@/components/CaretNav'
import { MessageProps } from './Message'
import MessageContainer from './MessageContainer'

const MessageBase = (props: MessageProps & { children?: ReactNode }) => {
  const { role, content, parts } = props.message
  const isUser = role === 'user'

  const [partIndex, setPartIndex] = useState(0)

  return (
    <MessageContainer role={role} message={props.message}>
      {isUser ? (
        <div
          className="userMessage"
          dangerouslySetInnerHTML={{
            __html: content.replace(
              /<(?!\/?br\/?.+?>|\/?img|\/?table|\/?thead|\/?tbody|\/?tr|\/?td|\/?th.+?>)[^<>]*>/gi,
              ''
            )
          }}
        ></div>
      ) : (
        <Flex direction="column">
          <Flex direction="column" gap="4">
            <Markdown>{content}</Markdown>
            {parts && parts.length > 0 && <p className="whitespace-pre-wrap">{parts[partIndex]}</p>}
            {props.children}
            {/* TODO: Parts go here */}
          </Flex>
          <div className="mt-1 flex gap-3 empty:hidden">
            {parts && parts.length > 1 && (
              <CaretNav value={partIndex} setValue={setPartIndex} total={parts.length} />
            )}
          </div>
        </Flex>
      )}
    </MessageContainer>
  )
}

export default MessageBase
