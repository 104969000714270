'use client'
import 'regenerator-runtime/runtime';
import React, { useCallback, useEffect } from 'react'
import { useWindowSize } from '@planda/design-system';
import { Flex, Tooltip, IconButton } from '@radix-ui/themes'
import ContentEditable from 'react-contenteditable'
import { AiOutlineLoading3Quarters, AiOutlineClear, AiOutlineUnorderedList } from 'react-icons/ai'
import { FiSend } from 'react-icons/fi'
import { useSpeechRecognition } from 'react-speech-recognition'
import './index.scss'
import Dictaphone from '../Dictaphone'
const HTML_REGULAR =
    /<(?!img|table|\/table|thead|\/thead|tbody|\/tbody|tr|\/tr|td|\/td|th|\/th|br|\/br).*?>/gi

const SendMessage = ({
    textAreaRef,
    message,
    setMessage,
    isLoading,
    sendMessage,
    clearMessages,
}: {
    textAreaRef: any
    message: string
    setMessage: (message: string) => void
    isLoading: boolean
    sendMessage: (e: any) => void
    clearMessages: () => void
}) => {
    const { transcript } = useSpeechRecognition()

    const { isMobile } = useWindowSize()


    const handleKeypress = useCallback(
        (e: any) => {
            if (e.keyCode == 13 && !e.shiftKey) {
                sendMessage(e)
                e.preventDefault()
            }
        },
        [sendMessage]
    )

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = '50px'
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight + 2}px`
        }
    }, [message, textAreaRef])

    useEffect(() => {
        if (!isLoading) {
            textAreaRef.current?.focus()
        }
    }, [isLoading])

    return (
        <div className="px-4 pb-3">
            <Flex align="end" justify="between" gap="3" className="relative">
                <div className="rt-TextAreaRoot rt-r-size-1 rt-variant-surface flex-1 rounded-3xl chat-textarea border">
                    <ContentEditable
                        innerRef={textAreaRef}
                        style={{
                            minHeight: '24px',
                            maxHeight: '200px',
                            overflowY: 'auto'
                        }}
                        className="rt-TextAreaInput text-base"
                        html={message}
                        disabled={isLoading}
                        onChange={(e) => {
                            setMessage(e.target.value.replace(HTML_REGULAR, ''))
                        }}
                        onKeyDown={(e) => {
                            handleKeypress(e)
                        }}
                    />
                    <div className="rt-TextAreaChrome"></div>
                </div>
                <Flex gap="3" className="absolute right-0 pr-4 bottom-3 pt">
                    {isLoading && (
                        <Flex
                            width="6"
                            height="6"
                            align="center"
                            justify="center"
                            style={{ color: 'var(--accent-11)' }}
                        >
                            <AiOutlineLoading3Quarters className="animate-spin size-4" />
                        </Flex>
                    )}
                    <Tooltip content={'Send Message'}>
                        <IconButton
                            variant="soft"
                            disabled={isLoading}
                            color="gray"
                            size="2"
                            className="rounded-xl cursor-pointer"
                            onClick={sendMessage}
                        >
                            <FiSend className="size-4" />
                        </IconButton>
                    </Tooltip>
                    <Dictaphone onSpeakEnd={(end) => setMessage(`${message}${message.endsWith(' ') ? '' : ' '}${end}`)} />
                    {!isMobile && <Tooltip content={'Clear Message'}>
                        <IconButton
                            variant="soft"
                            color="gray"
                            size="2"
                            className="rounded-xl cursor-pointer"
                            disabled={isLoading}
                            onClick={clearMessages}
                        >
                            <AiOutlineClear className="size-4" />
                        </IconButton>
                    </Tooltip>}
                    {/* {onToggleSidebar && !isMobile && <Tooltip content={'Toggle Sidebar'}>
                        <IconButton
                            variant="soft"
                            color="gray"
                            size="2"
                            className="rounded-xl md:hidden cursor-pointer"
                            disabled={isLoading}
                            onClick={onToggleSidebar}
                        >
                            <AiOutlineUnorderedList className="size-4" />
                        </IconButton>
                    </Tooltip>} */}
                </Flex>
            </Flex>
        </div>
    )
}

export default SendMessage