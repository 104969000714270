import { useCallback, useState } from "react"
import { IconButton, Tooltip } from "@radix-ui/themes"
import { FaRegCopy } from "react-icons/fa"
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard"

const CopyIcon = ({ content }: { content: string }) => {
    const copy = useCopyToClipboard()
    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)

    const onCopy = useCallback(() => {
        copy(content, (isSuccess) => {
            if (isSuccess) {
                setTooltipOpen(true)
            }
        })
    }, [content, copy])

    return (
        <Tooltip open={tooltipOpen} content="Copied!">
            <IconButton
                className="cursor-pointer"
                variant="outline"
                color="gray"
                onClick={onCopy}
                onMouseLeave={() => setTooltipOpen(false)}
            >
                <FaRegCopy />
            </IconButton>
        </Tooltip>
    )
}

export default CopyIcon