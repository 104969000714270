import { ComponentProps, ReactNode, forwardRef } from 'react'
import * as ToggleGroupPrimative from '@radix-ui/react-toggle-group'
import { Flex, Tooltip } from '@radix-ui/themes'
import styles from './ToggleGroup.module.css'

interface ToggleGroupItem {
    value: string,
    label: ReactNode,
    tooltip?: string,
    style?: any
}


type Props = ComponentProps<typeof ToggleGroupPrimative.Root> & { className?: string } // Omit<ComponentProps<typeof ToggleGroupRoot>, 'value' | 'onValueChange'>
export const ToggleGroup = forwardRef<HTMLDivElement, { items: ToggleGroupItem[], id?: string, error?: JSX.Element } & Props>(({ className, items, id, error, ...fields }, ref) => {
    return (
        <Flex>
            <ToggleGroupPrimative.Root
                className={
                    // clsx(styles.toggleGroupRoot, 'cursor-paint')
                    styles.toggleGroupRoot
                    // className ? clsx(styles.toggleGroupRoot, className) : styles.toggleGroupRoot
                }
                {...fields}
                ref={ref}
                id={id}
            >
                {
                    items.map(item => {
                        return (<Tooltip key={item.value} content={item.tooltip || `Set drag mode to ${item.label}`} side='top'>
                            <ToggleGroupPrimative.Item className={styles.toggleItem + (item.value === fields.value ? ' ' + styles.toggleItemOn : '')} style={item.style} key={item.value} value={item.value}>
                                {item.label}
                            </ToggleGroupPrimative.Item>
                        </Tooltip>)
                    })
                }
            </ToggleGroupPrimative.Root>
            {error}
        </Flex>
    )
})
ToggleGroup.displayName = 'ToggleGroup'

export default ToggleGroup


export const MultiToggleGroup = ({ items, id, ...field }: Omit<ToggleGroupPrimative.ToggleGroupMultipleProps, 'type'> & {
    items: ToggleGroupItem[], id?: string
}) => {
    return (
        <ToggleGroupPrimative.Root
            className={styles.toggleGroupRoot}
            {...field}
            id={id}
            type={'multiple'}
        >
            {
                items.map(item => <ToggleGroupPrimative.Item className={styles.toggleItem} key={item.value} value={item.value}>{item.label}</ToggleGroupPrimative.Item>)
            }
        </ToggleGroupPrimative.Root>
    )
}


// export const ToggleItem = styled(ToggleGroupPrimative.Item, {
//     all: 'unset',
//     backgroundColor: '$gray1',
//     color: '$outline',
//     // height: 35,
//     // width: 35,
//     padding: '0.5rem 0.75rem',
//     flex: '1',
//     display: 'flex',
//     fontSize: '0.7em',
//     lineHeight: 1,
//     alignItems: 'center',
//     justifyContent: 'center',
//     marginLeft: 1,
//     '&:first-child': { marginLeft: 0, borderTopLeftRadius: 4, borderBottomLeftRadius: 4 },
//     '&:last-child': { borderTopRightRadius: 4, borderBottomRightRadius: 4 },
//     '&:hover': { backgroundColor: '$primary3' }, // '$primary3'
//     '&[data-state=on]': {
//         position: 'relative',
//         // backgroundColor: '$primary5', color: '$primary11',
//         boxShadow: `$focus`
//     },
//     '&:focus': { position: 'relative', boxShadow: `$focus` },
// });
// export const ToggleGroupRoot = styled(ToggleGroupPrimative.Root, {
//     display: 'inline-flex',
//     backgroundColor: '$gray6',
//     borderRadius: 4,
//     boxShadow: `rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px`,
//     // boxShadow: '$boxShadow'
//     // boxShadow: `rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px`,
//     // flexBasis: '40%',
//     // flex: 1
// });

// const Flex = styled('div', {
//     all: 'unset',
//     flexGeneral: 'column'
// })