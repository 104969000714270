import { MS_PER_15_MINUTE } from "@planda/design-system"
import { INCREMENT_BY_MINUTES } from "@/constants"

const MULTIPLIER = Math.round(60 / INCREMENT_BY_MINUTES)
export const dateToIndices = (dateStart: number, start: number) => {
    const dayOrder = Math.round((dateStart - start) / MS_PER_15_MINUTE)
    const rowIndex = Math.floor(dayOrder / (24 * MULTIPLIER))
    const colIndex = dayOrder % (24 * MULTIPLIER)
    return { rowIndex, colIndex }
}


// TODO: check if this is correct
export const indicesToDate = (rowIndex: number, colIndex: number, start: number) => {
    return start + (rowIndex * 24 * MULTIPLIER + colIndex) * MS_PER_15_MINUTE
}