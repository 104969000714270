'use client'

/**
 * TODO: look at messages, use GPT, determine what's next.
 * First message should be calendar sync. If calendar already synced, say "Calendar synced", else show options.
 * 0. Check if calendar synced
 * 1. check if calendar credentials are still valid.
 *  - if not, prompt to re-authenticate
 * This should be it. It's just an availability filler
 */
import {
  forwardRef,
  useCallback,
  useContext,
} from 'react'
import { Flex, Heading, ScrollArea } from '@radix-ui/themes'
import toast from 'react-hot-toast'
import { fetcher } from '@/lib/fetch'
import { useAuthSWR } from '@/lib/swr'
import { Participant } from '@/types'
import ChatContext from './chatContext'
import JoinMessage from './messages/JoinMessage'
import Message from './messages/Message'
import SyncMessage from './messages/SyncMessage'
import WillingnessToRescheduleMessage from './messages/WillingnessToRescheduleMessage'
import SendMessage from './SendMessage'
import useChat from './useChat'
import { Chat, SingleChatHandlerInstance } from '../../types/message'
import Loader from '../Loader'

import './index.scss'
const HTML_REGULAR =
  /<(?!img|table|\/table|thead|\/thead|tbody|\/tbody|tr|\/tr|td|\/td|th|\/th|br|\/br).*?>/gi

export interface ChatProps {
  type: 'individual' | 'group'
}

export const postChatOrQuestion = async (chat: Chat, messages: any[], input: string, userId?: string, type = 'individual') => {
  const url = `/api/chat/${chat.id}/${type}`

  const data = {
    prompt: chat?.persona?.prompt,
    messages: [...messages!],
    input
  }

  return await fetcher<{ message: string } | undefined>(url, 'POST', data, { userid: userId })

  // return await fetch(url, {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json'
  //   },
  //   body: JSON.stringify(data)
  // })
}
// need participant info, put in context or here? participant info probably only here, not group chat
const Chat = (props: ChatProps, ref: any) => {
  const { debug, currentChatRef, user } =
    useContext(ChatContext)

  const { textAreaRef, bottomOfChatRef,
    message, setMessage, currentMessage, setCurrentMessage, sendMessageProps,
    isLoading, setIsLoading, conversation }
    = useChat(ref, 'individual')

  const id = currentChatRef?.current?.id
  const { data: participantData, isLoading: participantIsLoading } = useAuthSWR<Participant>(user?.userId, id && `/api/meeting/${id}/participant`)

  const sendMessage = useCallback(
    async (e: any) => {
      if (!isLoading) {
        e.preventDefault()
        const input = textAreaRef.current?.innerHTML?.replace(HTML_REGULAR, '') || ''

        if (input.length < 1) {
          toast.error('Please type a message to continue.')
          return
        }
        const message = [...(conversation.current || [])]
        conversation.current = [...(conversation.current || []), { content: input, role: 'user' }]
        setMessage('')
        setIsLoading(true)
        try {
          const response = await postChatOrQuestion(currentChatRef?.current!, message, input, user?.userId)

          if (response) {
            const data = response.message

            if (!data) {
              throw new Error('No data')
            }

            let done = false
            let resultContent = ''

            const char = data

            setCurrentMessage((state) => {
              if (debug) {
                console.log({ char })
              }
              resultContent = state + char
              return resultContent
            })
            done = true

            // while (!done) {
            //   try {
            //     const { value, done: readerDone } = await reader.read()
            //     const char = decoder.decode(value)
            //     if (char) {
            //       setCurrentMessage((state) => {
            //         if (debug) {
            //           console.log({ char })
            //         }
            //         resultContent = state + char
            //         return resultContent
            //       })
            //     }
            //     done = readerDone
            //   } catch {
            //     done = true
            //   }
            // }
            // The delay of timeout can not be 0 as it will cause the message to not be rendered in racing condition
            setTimeout(() => {
              if (debug) {
                console.log({ resultContent })
              }
              conversation.current = conversation.current || []
              conversation.current = [
                ...conversation.current,
                { content: resultContent, role: 'assistant' }
              ]

              setCurrentMessage('')
            }, 1)
          }
          // else {
          //   // const result = await response.json()
          //   // if (response.status === 401) {
          //   //   conversation.current.pop()
          //   //   location.href =
          //   //     result.redirect +
          //   //     `?callbackUrl=${encodeURIComponent(location.pathname + location.search)}`
          //   } else {
          //     toast.error(result.error)
          //   }
          // }

          setIsLoading(false)
        } catch (error: any) {
          console.error(error)
          toast.error(error.message)
          setIsLoading(false)
        }
      }
    },
    [currentChatRef, debug, isLoading]
  )

  // useEffect(() => {
  //   // check if synced, if not, prompt to sync
  //   // if conversation.current.length === 0, initial sync message.
  //   // if conversation.current.length > 0, check if credentials still valid, if not, prompt to re-authenticate
  // }, [])

  return (
    <Flex direction="column" height="100%" className="relative" gap="3"
      style={{ flex: 1, position: 'relative', backgroundColor: 'var(--color-background)' }}
    >
      <Flex
        justify="between"
        align="center"
        py="3"
        px="4"
        style={{ backgroundColor: 'var(--gray-a2)' }}
      >
        {(participantData || currentChatRef?.current?.persona?.name) ? <Heading size="4">{
          participantData?.isOrganizer ? "Organizer" : (currentChatRef?.current?.persona?.name || 'None')
        }</Heading> : <Loader />}
      </Flex>
      <ScrollArea
        className="flex-1 px-4"
        type="auto"
        scrollbars="vertical"
        style={{ height: '100%' }}
      >
        <JoinMessage />
        <SyncMessage />
        <WillingnessToRescheduleMessage />
        {conversation.current?.map((item, index) => (
          <Message key={index} message={item} />
        ))}
        {(currentMessage || isLoading) && <Message isLoading={isLoading} message={{ content: currentMessage, role: 'assistant' }} />}
        <div ref={bottomOfChatRef}></div>
      </ScrollArea>
      <SendMessage
        sendMessage={sendMessage}
        {...sendMessageProps}
      />
    </Flex>
  )
}

export default forwardRef<SingleChatHandlerInstance, ChatProps>(Chat)
