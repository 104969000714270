import { z } from "zod";
import { CalendarIdSchema } from "./calendar";

// next-auth table
export const SyncStatusSchema = z.record(CalendarIdSchema, z.boolean())
// z.object({
//     google: z.boolean(),
// }).partial()
export type SyncStatus = z.infer<typeof SyncStatusSchema>

// TODO: not implemented yet
// idea is that chat's can be reused after meeting, if you want to meet with same group.
// every new meeting takes default settings as last meeting, or most common meeting settings used by this group.
export const ChatSchema = z.object({
    // separate from meetingId, since one chat can have multiple meetings
    // is this too complicated?
    chatId: z.string(),
    id: z.string(),
    name: z.string(),
    nextPendingMeetingId: z.string().optional(), // undefined if meetings have passed, and none being scheduled rn
})

export const ChatMeetingSchema = z.object({
    chatId: z.string(),
    id: z.string(), // MEETING#<createdOn>
    meetingId: z.string(),
    status: z.enum(['pending', 'confirmed', 'cancelled']),
})
