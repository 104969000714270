'use client'
import { Flex } from '@radix-ui/themes'
import Spinner from '@/components/Spinner'
import MessageBase from './MessageBase'
import { ChatMessageSimple } from '../../../types/message'
import CopyIcon from '../../CopyIcon'

export interface MessageProps {
  message: ChatMessageSimple,
  isLoading?: boolean,
  command?: string, // tells you which options to show
}

const Message = (props: MessageProps) => {
  const { role, content } = props.message
  const isUser = role === 'user'

  return (
    <MessageBase {...props}>
      {
        props.isLoading ? (
          <Spinner />
        ) : (
          <>
            <Flex gap="4" align="center">
              <CopyIcon content={content} />
            </Flex>
            <Flex wrap={'wrap'} gap="4" align="center">
              {/* Other user questions can also go here */}
              {/* Google, Apple, etc. buttons. These buttons either open in dialog, or pop-up, */}
              {/* Buttons can go here, as a grid */}
            </Flex>
          </>
        )
      }
    </MessageBase>
  )
}

export default Message
