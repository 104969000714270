'use client'
import { useContext } from 'react'
import { makeScheduleMatrix } from '@planda/design-system'
import { Button, Flex } from '@radix-ui/themes'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import { signIn, useSession } from 'next-auth/react'
import { PiPlayFill } from 'react-icons/pi'
import { RiRobot2Line } from 'react-icons/ri'
import { ChatContext, Markdown } from '@/components'
import { INCREMENT_BY_MINUTES } from '@/constants'
import { GOOGLE_CALENDAR_SCOPES } from '@/constants/external'
import AvailabilitiesTimegrid from '@/features/drag-calendar'
import MessageContainer from './MessageContainer'
import { GoogleButton } from '../../SignInButtons'

const SyncMessage = ({}: {}) => {
  const { syncStatus = {} } = useContext(ChatContext)
  // console.log("syncStatus", syncStatus, isSynced)
  // check if already synced
  const { status } = useSession()

  // TODO: put back after demo, has bugs, will redirect when you should be
  // if (isSynced && status === 'unauthenticated') {
  //   signIn('google', { redirect: false })
  // }

  const syncedCalendars = Object.keys(syncStatus || {})
    .filter((key) => syncStatus[key as keyof typeof syncStatus])
    .join(', ')
  const isSynced = !!syncedCalendars

  const content = isSynced
    ? `Availabilities synced! [Edit here](/chat/calendar) ${syncedCalendars}`
    : 'Fill in your availabilities [here](/chat/calendar), or sync with your digital calendar.'

  // TODO: handle chat loading

  return (
    <MessageContainer role="assistant">
      <Flex direction="column" gap="4">
        <Markdown>{content}</Markdown>
        {!isSynced && (
          <>
            <Flex wrap={'wrap'} gap="4" align="center">
              {/* Other user questions can also go here */}
              {/* Google, Apple, etc. buttons. These buttons either open in dialog, or pop-up, */}
              {/* Buttons can go here, as a grid */}
              <GoogleButton
                text="Sync with Google"
                onClick={async () => {
                  await signIn(
                    'google',
                    {
                      // callbackUrl,
                      redirect: false
                    },
                    {
                      scope: GOOGLE_CALENDAR_SCOPES.join(' '),
                      prompt: 'consent',
                      access_type: 'offline'
                    }
                  )
                  // window.confirm('You have successfully synced with Google Calendar!')
                  // router.push(callbackUrl + `&editing=true`)
                }}
              />
              <Link href="/chat/calendar">
                <Button variant="outline" color={'gray'}>
                  Fill in Available times
                </Button>
              </Link>
            </Flex>
            <CalendarFrame />
          </>
        )}
      </Flex>
    </MessageContainer>
  )
}

export default SyncMessage

const CalendarFrame = () => {
  // calendar
  const overlay = makeScheduleMatrix(0, INCREMENT_BY_MINUTES)
  const router = useRouter()

  // give it a thick border
  return (
    <Link href="/chat/calendar">
      <div
        className="relative w-10/12 h-64 border-4 border-gray-700 rounded-lg"
        onClick={() => router.push('/chat/calendar')}
      >
        <AvailabilitiesTimegrid
          hideHourLabels
          containerCss={{ pointerEvents: 'none' }}
          calendarOnly
          handleEdit={() => {}}
          firstDay={new Date()}
          myOverlay={overlay}
        />
        {/* create an overlay, that doesn't prevent click through */}
        <div
          className="absolute z-[5] flex justify-center items-center inset-0 size-full rounded-lg"
          style={{ backgroundColor: 'var(--black-a1)' }}
        >
          <PiPlayFill className="origin-center text-green-600 size-16 absolute hover:size-20 transition-all" />
        </div>
      </div>
    </Link>
  )
}
