import { z } from 'zod'

export * from './meeting'
export * from './user'
export * from './participant'
export * from './availability'
export * from './chat'
export * from './invite'

export const NextAuthTableItemSchema = z
    .object({
        pk: z.string(),
        sk: z.string()
    })
    .passthrough()

export type Falsy<T> = T | null | undefined | false
