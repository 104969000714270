'use client'
import { useContext, useMemo, useState } from 'react';
import { DayOfWeekNum, useWindowSize, useCurrentState, Flex, MS_PER_HOUR } from '@planda/design-system';
import { Button } from '@radix-ui/themes';
import { addMinutes, startOfDay } from 'date-fns';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import { PiEyedropperFill, PiPaintBrush } from "react-icons/pi";
import { ChatContext } from '@/components';
import { Info } from '@/components/Info';
import Loader from '@/components/Loader';
import ToggleGroup from '@/components/ToggleGroup';
import Cell from './Cell';
import { HEIGHT_OF_TIMEGRID_CELL_IN_REM, HEIGHT_OF_TIMEGRID_CELL_IN_REM_MOBILE } from './constants';
import DayOfWeekLabels from './DayOfWeekLabels';
import HourLabels from './HourLabels';
import styles from './index.module.css';
import { useRnd } from './useRnd';
import { Mode, modeToColor } from './utils';

export type Layout = 'default' | 'paper'

const modeToggleOptions = [{
    label: 'Auto',
    value: 'auto',
    style: { backgroundColor: 'var(--gray-3)', minWidth: 15 },
},
{
    label: 'free',
    value: Mode.Available.toString(),
    style: { backgroundColor: 'var(--grass-7)', width: 15 },
},
{
    label: 'busy',
    value: Mode.Will_Move_If_Must.toString(),
    tooltip: 'May move if must',
    style: { backgroundColor: 'var(--orange-7)', width: 15 },
},
{
    label: 'X',
    value: Mode.Unavailable.toString(),
    tooltip: 'Absolutely will not move',
    style: { backgroundColor: 'var(--red-8)', width: 15 },
},
{
    label: 'unset',
    value: Mode.Neutral.toString(),
    style: { backgroundColor: modeToColor(Mode.Neutral), width: 15 },
},
    // const DOW_GROUP_ITEMS = DAYS_OF_WEEK.map((day: string, i: number) => ({ value: i.toString(), label: day[0] }))
]

export enum GridTool {
    Drag = 'drag',
    Paint = 'paint'
}
const toggleOptions = [
    {
        label: <PiPaintBrush />,
        value: GridTool.Drag,
        style: { backgroundColor: modeToColor(Mode.Neutral), width: 15 },
    },
    {
        label: <PiEyedropperFill />,
        value: GridTool.Paint,
        style: { backgroundColor: modeToColor(Mode.Neutral), width: 15 },
    },
]

const AvailabilitiesTimegrid = ({
    myOverlay,
    firstDay,
    containerCss,
    setIsDragging: setIsDraggingExternal,
    disableScroll = false,
    handleEdit,
    calendarOnly = false,
    hideHourLabels = false,
    isLoading,
}: {
    myOverlay: number[][],
    firstDay: Date | number,
    containerCss?: any,
    setIsDragging?: (isDragging: boolean) => void,
    disableScroll?: boolean,
    handleEdit?: (dates: number[], state: Mode) => void,
    calendarOnly?: boolean,
    hideHourLabels?: boolean,
    isLoading?: boolean
}) => {
    const { isMobile } = useWindowSize('mobile')
    const startHour = isMobile ? 7 : 0
    const endHour = isMobile ? 21 : 24
    let days = myOverlay.length
    const incrementInMinutes = Math.round(60 / (myOverlay[0].length / 24))
    const blocksPerHour = Math.round(60 / incrementInMinutes)

    // onClosePersonaPanel
    const { onClosePersonaPanel } = useContext(ChatContext)

    const [selectedDragMode, setSelectedDragMode] = useState<Mode | 'auto'>('auto')
    const [toolType, setToolType] = useState<GridTool>(GridTool.Drag)

    const searchParams = useSearchParams()

    const inviteId = searchParams.get('invite')

    // if (windowSize.width && windowSize.width < 750) { days = Math.min(days, 3); firstDay = nextDay(firstDay, new Date().getDay() as DayOfWeekNum) }
    // else if (windowSize.width && windowSize.width < 900) { days = Math.min(days, 5); firstDay = nextDay(firstDay, new Date().getDay() as DayOfWeekNum) }
    const [isDragging, setIsDraggingInternal, isDraggingRef] = useCurrentState(false)

    const setIsDragging = (isDragging: boolean) => {
        setIsDraggingExternal?.(isDragging)
        setIsDraggingInternal(isDragging)
    }

    const startDate = useMemo(() => startOfDay(firstDay), [firstDay])

    const startBlock = Math.round(startHour * blocksPerHour)
    const endBlock = endHour * blocksPerHour
    // console.log("startBlock", startBlock, blocksPerHour, myOverlay[0].length)
    const blocksPerDay = myOverlay[0].length - startBlock - (myOverlay[0].length - endBlock)

    const { invisibleProps, dragAreaRef, } = useRnd(startDate, days, myOverlay, isDraggingRef, incrementInMinutes, selectedDragMode, {
        handleEdit, setIsDragging,
        bounds: { startBlock, startOffsetMs: Math.round(startHour * MS_PER_HOUR), endBlock: endHour * blocksPerHour }
    })

    const myCells = useMemo(() => {
        return myOverlay.flatMap((days, i) => {
            return days.slice(startBlock, endBlock).map((count, j) => {
                j += startBlock
                const date = addMinutes(startDate, Math.round(60 * 24 * i + j * incrementInMinutes))
                const isAlmostStartOfHour = date.getMinutes() === Math.round(60 - incrementInMinutes)
                const isAlmostMiddleOfHour = date.getMinutes() === Math.round(30 - incrementInMinutes)
                return (
                    <Cell
                        availability={count}
                        isAlmostStartOfHour={isAlmostStartOfHour}
                        isAlmostMiddleOfHour={isAlmostMiddleOfHour}
                        date={date.getTime()}
                        key={date.getTime()} />
                )
            })
        })
    }, [myOverlay, incrementInMinutes, isMobile])

    return (
        <div className={styles.container} style={containerCss}>
            {!calendarOnly && <Flex gap={'3'} align={'center'}>
                <ToggleGroup
                    defaultValue='auto'
                    type='single'
                    value={selectedDragMode.toString()}
                    className='cursor-pointer'
                    onValueChange={(value) => {
                        setSelectedDragMode(value === 'auto' ? value : parseInt(value) as Mode | 'auto')
                    }}
                    items={modeToggleOptions}
                />
                <Info content="Everything here is private. Others will only see the times we (Ethospire) suggest." />
                {/* <Link className='ml-auto' href='/chat'> */}
                <Button radius={'full'}
                    onClick={onClosePersonaPanel}
                    // className='bottom-6 mx-auto'
                    size='2'>Done</Button>
                {/* </Link> */}
            </Flex>}
            <div className={styles.calendar}>
                <DayOfWeekLabels
                    weekday={isMobile ? 'narrow' : 'short'}
                    firstDay={startDate} days={days} />

                {!hideHourLabels && <HourLabels
                    startHour={startHour}
                    endHour={endHour}
                    incrementInMinutes={incrementInMinutes}
                    hideMeridian={isMobile}
                    disableScroll={disableScroll} />}
                <div className={styles.grid}
                    ref={dragAreaRef}
                    style={{
                        gridTemplateColumns: `repeat(${days}, 1fr)`,
                        // grid-template-rows: repeat(28, var(--height-of-timegrid-cell-mobile));
                        // gridTemplateRows: `repeat(${blocksPerDay}, var(--height-of-timegrid-cell))`,
                        gridTemplateRows: `repeat(${blocksPerDay}, ${isMobile ? HEIGHT_OF_TIMEGRID_CELL_IN_REM_MOBILE : HEIGHT_OF_TIMEGRID_CELL_IN_REM}rem)`,
                    }}
                >
                    <div {...(invisibleProps as any)} />
                    {
                        myCells
                    }
                    {isLoading && <Loader className='m-auto absolute' />}
                </div>
            </div>
        </div>
    )
}

export default AvailabilitiesTimegrid
