'use client'
import { ReactNode, useContext } from 'react'
import { Avatar, Flex, Popover } from '@radix-ui/themes'
import { HiUser } from 'react-icons/hi'
import { RiRobot2Line } from 'react-icons/ri'
import { ChatRole } from '@/components'
import { useAuthSWR } from '@/lib/swr'
import { ChatMessageSimple } from '../../../types/message'
import { Participant } from '../../../types/participant'
import ChatContext from '../chatContext'

const MessageContainer = (props: {
  children?: ReactNode
  role: ChatRole
  message?: ChatMessageSimple
}) => {
  const { role, children } = props
  const isUser = role === 'user'
  const { user, meeting } = useContext(ChatContext)
  const isMe = props.message && props.message.userId === user?.userId

  const { data, error, mutate } = useAuthSWR<Participant>(
    user?.userId,
    meeting?.meetingId &&
    props.message?.userId &&
    !isMe &&
    `/api/meeting/${meeting.meetingId}/participant/${props.message.userId}`
  )

  return (
    <Flex
      gap="4"
      className="mb-5"
      direction={isUser ? 'row-reverse' : 'row'}
      justify={isUser ? 'end' : 'start'}
    >
      <Popover.Root>
        <Popover.Trigger>
          <div>
            <Avatar
              fallback={
                isUser ? <HiUser className="size-4" /> : <RiRobot2Line className="size-4" />
              }
              color={isUser ? undefined : 'green'}
              size="2"
              radius="full"
            />
          </div>
        </Popover.Trigger>
        <Popover.Content side="top" sideOffset={7} align="center">
          <div>{isUser ? (isMe ? 'You' : data?.name || 'Loading...') : 'Spire'}</div>
        </Popover.Content>
      </Popover.Root>

      <div className="flex-1 pt-1 break-word" style={{ textAlign: isUser ? 'right' : 'left' }}>
        {children}
      </div>
    </Flex>
  )
}

export default MessageContainer
