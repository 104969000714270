// meet-table
import z from 'zod';

export const MeetingTypeEnum = z.enum(['casual', 'business', 'event'])
export type MeetingType = z.infer<typeof MeetingTypeEnum>

export const MeetingEventSchema = z.object({
    start: z.number(),
    participants: z.array(z.string()).optional(),
})

export type MeetingEvent = z.infer<typeof MeetingEventSchema>

export const MeetingStatusEnum = z.enum(['rescheduling', 'cancelled', 'confirmed', 'scheduling'])
export type MeetingStatus = z.infer<typeof MeetingStatusEnum>

export const MeetingSchema = z.object({
    meetingId: z.string(), // <meetingId>
    id: z.string(), // MEETING_SETTINGS
    name: z.string(),
    start: z.number().or(z.literal('today')).default('today'),
    end: z.number().optional(),
    durationInMinutes: z.number(),
    useParticipantSlider: z.boolean(),
    useOrganizerSlider: z.boolean(),
    creator: z.string(), // userId
    owner: z.string(), // userId
    type: MeetingTypeEnum.default('casual'),
    timezone: z.string().optional(),
    // useChat: z.boolean(),
    creatorName: z.string().optional(),
    confirmedEvent: MeetingEventSchema.optional(),
    status: MeetingStatusEnum.default('scheduling'),
})

export type Meeting = z.infer<typeof MeetingSchema>

export const NewMeetingSchema = MeetingSchema.partial({
    meetingId: true, id: true, start: true,
    name: true, durationInMinutes: true, useParticipantSlider: true,
    useOrganizerSlider: true,
    creator: true, owner: true,
    status: true,
    type: true,
}).extend({
    type: MeetingTypeEnum.default('casual').optional(),
})

export type NewMeeting = z.infer<typeof NewMeetingSchema>
