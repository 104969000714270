import { z } from "zod"
import { GoingStateEnum } from "./participant"
// user-table
export const UserSchema = z.object({
    userId: z.string(), // USER#userId
    id: z.string(), // MEET_USER
    name: z.string(), // can be empty string for temporary users
    timezone: z.string().optional(),
    accountType: z.enum(["temp", "verified"]), // should have an expires attribute
})
export type User = z.infer<typeof UserSchema>

export const UserMeetingSchema = z.object({
    userId: z.string(), // USER#userId
    id: z.string(), // MEETING#<meetingId>
    name: z.string(),
    state: GoingStateEnum.optional(),
})
export type UserMeeting = z.infer<typeof UserMeetingSchema>

export const DBUserEventSchema = z.object({
    userId: z.string(), // USER#userId
    id: z.string(), // EVENT#<meetingId>@<dateStart
    expiresAt: z.number(),
    state: GoingStateEnum.optional(),
    googleCalendarId: z.string().optional(),
})
export type DBUserEvent = z.infer<typeof DBUserEventSchema>

export const UserEventSchema = DBUserEventSchema
    .omit({ expiresAt: true, userId: true, id: true })
    .extend({
        meetingId: z.string(),
        dateStart: z.number(),
    })

export type UserEvent = z.infer<typeof UserEventSchema>