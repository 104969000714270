'use client'
import { isNil } from "lodash-es"
import { useSession } from "next-auth/react"
import useSWR0 from "swr"
import { Falsy } from "@/types"
import { fetchGet } from "./fetch"


const useSWR = <T>(url: Falsy<string>, options?: { onSuccess?: (data: T) => void, onError?: (err: any) => void, shouldRetryOnError?: boolean }) => {
    const props = useSWR0(url, fetchGet<T>, { ...options })

    return props
}

export default useSWR

export const useAuthSWR = <T>(userId: Falsy<string>, url: Falsy<string>, options?: { onSuccess?: (data: T) => void, onError?: (err: any) => void, shouldRetryOnError?: boolean }) => {
    const { status } = useSession()
    const props = useSWR0(url && (userId || status !== 'unauthenticated') && [url, userId], (res: any) => fetchGet<T>(res[0], userId ? { userid: userId } : undefined), { ...options })

    return { ...props, isLoading: props.isLoading || !userId }
}

export const useMeetingSWR = <T>(meetingId: Falsy<string>, userId: Falsy<string>, uri: Falsy<string>, options?: { onSuccess?: (data: T) => void, onError?: (err: any) => void, shouldRetryOnError?: boolean }) => {
    const { status } = useSession()
    const url = meetingId ? `/api/meeting/${meetingId}/${uri}` : null
    const props = useSWR0(meetingId && !isNil(url) && (userId || status === 'authenticated') && [url, userId],
        (res: any) => fetchGet<T>(res[0], userId ? { userid: userId } : undefined), { ...options })

    return { ...props, isLoading: props.isLoading || !userId }
}

// export const useAuthSWRCustom = <T>(userId: Falsy<string>, url: Falsy<string>, options?: { onSuccess?: (data: T) => void, onError?: (err: any) => void, shouldRetryOnError?: boolean }) => {
//     const props = useSWR0(url && userId && [url, userId], (res: any) => fetcher<T>(res[0], { userid: userId }), { ...options })

//     // const props = useSWR0([path, name], ([path, _]) => fetchGet<T>(`/api/${meetingId}/${path}`, name ? { name } : undefined), options)

//     return props
// }