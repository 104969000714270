import { useForageItem } from "@planda/design-system"
import { nanoid } from "nanoid"
import { useSession } from "next-auth/react"
import { useAuthSWR } from "@/lib/swr"
import { User } from "@/types/user"

export const useUser = () => {
    const defaultId = nanoid()
    const { item: userId, set: setUserId, isLoading } = useForageItem<string | undefined>('userId',
        (x) => x === undefined || typeof x === 'string', undefined, (x) => !x && setUserId(defaultId))
    const { data } = useSession()

    if (data && data.id !== userId) setUserId(data.id)

    // pass userId into profile
    const { data: user } = useAuthSWR<User>(userId, !isLoading && `/api/user/profile`, {
        onSuccess: (user) => {
            user.userId && setUserId(user.userId)
        },
        shouldRetryOnError: false
    })

    useAuthSWR<User>(userId, !isLoading && `/api/user/profile`, {
        onSuccess: (user) => {
            user.userId && setUserId(user.userId)
        },
        shouldRetryOnError: false
    })

    // useEffect(() => {
    //     if (!joinId) return

    //     fetcher(`/api/invite/${joinId}`, 'PUT', undefined, userId && { userid: userId })
    //     router.replace(`/chat`)
    //     // TODO: join meeting
    // }, [joinId])

    return user
}
