"use client"
/**
 * Author: Christina Yang
 */
import React from 'react'
import { InfoCircledIcon } from '@radix-ui/react-icons'
import { Tooltip } from '@radix-ui/themes'

export const Info = ({ content, style }: { content: string, style?: any }) => {
    return (
        <Tooltip delayDuration={300} content={content}>
            <InfoCircledIcon
                className='origin-center w-max-content flex-inline justify-center items-center vertical-middle'
                style={{
                    // transformOrigin: 'center', width: 'max-content', display: 'flex-inline', justifyContent: 'center', alignItems: 'center', verticalAlign: 'middle',
                    ...style
                }} />
        </Tooltip>
    )
}
