'use client'

import React, { useContext } from 'react'
import { Box, Flex, IconButton, Text } from '@radix-ui/themes'
import cs from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { BiMessageDetail } from 'react-icons/bi'
import { FiPlus } from 'react-icons/fi'
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { RiRobot2Line } from 'react-icons/ri'
import ChatContext from './chatContext'
import './index.scss'
import Loader from '../Loader'

export const DesktopSideBar = ({ className }: { className?: string }) => {
  const {
    currentChatRef,
    chatList,
    isLoading,
    toggleSidebar,
    onDeleteChat,
    onCreateChat,
  } = useContext(ChatContext)

  const router = useRouter()

  return (
    <Flex direction="column" className={cs('chart-side-bar', { show: toggleSidebar }, className)}
      style={toggleSidebar ? { minWidth: '256px' } : undefined}
    >
      <Flex className="p-2 h-full overflow-hidden w-64" direction="column" gap="3">
        <Box
          width="auto"
          height={'40px'}
          onClick={() => onCreateChat?.({})}
          className="bg-token-surface-primary active:scale-95 cursor-pointer"
        >
          <FiPlus className="size-4" />
          <Text>New Chat</Text>
        </Box>
        {/* <ScrollArea className="flex-1 max-w-full" type="auto" scrollbars="vertical"> */}
        <Flex className='max-w-full overflow-auto mb-auto' direction="column" gap="3">
          {chatList.map((chat) => {
            const isActive = currentChatRef?.current?.id === chat.id
            return (
              // <Link key={chat.id} href="/chat">
              <Box
                key={chat.id}
                width="auto"
                className={cs('bg-token-surface active:scale-95 truncate cursor-pointer max-w-full', {
                  active: isActive
                })}
                onClick={() => {
                  router.push(`/chat/${chat.id}`)
                }}
                style={{ minHeight: 40 }}
              // style={{ maxWidth: 280, maxWidth: 240, }}
              >
                <Flex className='min-w-0' gap="2" align="center">
                  <BiMessageDetail className="size-4" />
                  <Text style={{ minWidth: 0, flex: 1 }} as="p" className="truncate">
                    {chat.name || chat.persona?.name}
                  </Text>
                </Flex>
                <Flex gap="2" align="center">
                  <IconButton
                    size="2"
                    className="cursor-pointer"
                    variant="ghost"
                    color="gray"
                    radius="full"
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  >
                    <HiOutlineDotsHorizontal className="size-4" />
                  </IconButton>
                  <IconButton
                    size="2"
                    className="cursor-pointer"
                    variant="ghost"
                    color="gray"
                    radius="full"
                    onClick={(e) => {
                      e.stopPropagation()
                      onDeleteChat?.(chat)
                    }}
                  >
                    <AiOutlineCloseCircle className="size-4" />
                  </IconButton>
                </Flex>
              </Box>
              // </Link>
            )
          })}
          {isLoading && <Loader className='m-auto' />}
        </Flex>
        {/* </ScrollArea> */}
        <Link href={'/pricing'}>
          <Box
            width="auto"
            // onClick={() => onCreateChat?.(DefaultPersonas[0])}
            className="bg-token-surface-primary-solid active:scale-95 cursor-pointer gradient-box flex items-center"
          >
            <FiPlus className="size-4" />
            <Text>Business Chat</Text>
          </Box>
        </Link>
        <Link href={'/pricing'}>
          <Box
            width="auto"
            // onClick={() => onCreateChat?.(DefaultPersonas[0])}
            className="bg-token-surface-primary-solid active:scale-95 cursor-pointer gradient-box-warm flex items-center"
          >
            <FiPlus className="size-4" />
            <Text>Events Chat</Text>
          </Box>
        </Link>
        <Link href="/chat/calendar">
          <Box
            width="auto"
            // onClick={() => onOpenPersonaPanel?.('chat')}
            className="bg-token-surface-primary active:scale-95 cursor-pointer"
          >
            <RiRobot2Line className="size-4" />
            <Text>Saved Availabilities</Text>
          </Box>
        </Link>
      </Flex>
    </Flex>
  )
}

export default DesktopSideBar
