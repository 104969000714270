import { z } from "zod"
import { MeetingWithUser } from "../components/Chat/useChatList"

export const ChatMessageSchema = z.object({
  content: z.string(),
  role: z.union([z.literal('assistant'), z.literal('user'), z.literal('system')]),
  chatId: z.string(), // <meetingId>/<userId> OR <meetingId>/group
  id: z.string(), // <userId>/<messageId>
  userId: z.string(),
  meetingId: z.string(),
  messageId: z.string(), // nanoid(8)
  createdAt: z.number(),
  type: z.union([z.literal('individual'), z.literal('group')]),
  parts: z.array(z.string()).optional(),
  handled: z.boolean().optional(),
  options: z.record(z.any()).optional()
})

export const ChatMessageDBSchema = ChatMessageSchema.omit({
  userId: true,
  meetingId: true,
  messageId: true,
  type: true,
  role: true
})
export type ChatMessageDB = z.infer<typeof ChatMessageDBSchema>
export type ChatMessage = z.infer<typeof ChatMessageSchema>

export const NewChatMessageSchema = ChatMessageSchema.partial({
  id: true,
  createdAt: true,
  chatId: true,
  userId: true,
  messageId: true,
  role: true,
})

export type NewChatMessage = z.infer<typeof NewChatMessageSchema>

export interface ChatMessageSimple extends Partial<ChatMessage> {
  content: string
  role: ChatRole,
  parts?: string[],
  options?: Record<string, any>
}

export interface Persona {
  id?: string
  role: ChatRole
  avatar?: string
  name?: string
  prompt?: string
  key?: string
  isDefault?: boolean,
  description?: string
}

export type Chat = {
  id: string
  persona?: Persona
  messages?: ChatMessageSimple[]
} & MeetingWithUser

export type ChatRole = 'assistant' | 'user' | 'system'

export interface ChatGPInstance {
  setConversation: (messages: ChatMessageSimple[]) => void
  setGroupConversation: (messages: ChatMessageSimple[]) => void
  getConversation: () => ChatMessageSimple[]
  getGroupConversation: () => ChatMessageSimple[]
  focus: () => void
}

export type SingleChatHandlerInstance = {
  setConversation: (messages: ChatMessageSimple[]) => void
  getConversation: () => ChatMessageSimple[]
  focus: () => void
}
