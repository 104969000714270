import { isEmpty } from "lodash-es";
import { z } from "zod"

export const AvailabilityBlockSchema = z.object({
    dateStart: z.number(), // timestamp, is divisible by 15 min
    priority: z.number(),
    calendarId: z.string().optional(),
    // minutes: z.number().optional(), // don't include this, default is 15 min
})

export const DBAvailBlockSchema = z.object({
    userId: z.string(),
    id: z.string(), // T-<prefix>#<timestamp>
    // priority: z.number(),
    calendarId: z.string(),
    expiresAt: z.number().optional(),
    m0: z.number().optional(),
    m1: z.number().optional(),
    m2: z.number().optional(),
    m3: z.number().optional(),
    // minutes: z.number().optional(), // don't include this, default is 15 min
})

export const WeightedAvailabilityBlockSchema = z.object({
    dateStart: z.number(), // timestamp, is divisible by 15 min
    priority: z.number(),
    willingnessToReschedule: z.number() // willingness of participant to reschedule
    // minutes: z.number().optional(), // don't include this, default is 15 min
})

export enum AvailabilityModeEnum { // duplicate/copy of Mode enum in drag-calendar
    Available = -1,
    Will_Move_If_Must = 1,
    Unavailable = 2,
    Neutral = 0
}
export const AvailabilityModeSchema = z.nativeEnum(AvailabilityModeEnum);

export const CommonAvailabilityBlockSchema = z.object({
    dateStart: z.number(), // start timestamp, is divisible by 15 min
    dateEnd: z.number(), // end timestamp, is divisible by 15 min
    availableUserCount: z.number().optional(), // count of number of users available
    availabilityScore: z.number(), // sum of availabilities score
    busyUserIds: z.string().array().optional(), // list of userIds of unavailable users
    votes: z.record(AvailabilityModeSchema).optional(), // AvailabilityVote = Mode (same enum)
})

export type CommonAvailabilityBlock = z.infer<typeof CommonAvailabilityBlockSchema>

export type AvailabilityBlock = z.infer<typeof AvailabilityBlockSchema>
export type WeightedAvailabilityBlock = z.infer<typeof WeightedAvailabilityBlockSchema>

export type Schedule = {
    matrix: number[][],
}

export type AvailabilityLabelResponse = {
    availabilityLabels: SuggestedTime[],
    // CommonAvailabilityBlock[], // array of strings
    nextPage: number // number indicating next page to pass in
}

const AvailLabelSchema = z.object({
    label: z.string().optional(),
    dateStart: z.number(),
    dateEnd: z.number(),
    availabilityScore: z.number(),
})

export const DBSuggestedTimesSchema = z.object({
    // meetingId: z.string(), // pk
    // id: z.literal('AVAIL_LABELS'), // sk
    times: AvailLabelSchema.array(),
    // record(z.number())
}).passthrough()

// const availabilities: {
//     hasWorsened: boolean;
//     availabilityScore: number;
//     availability: {
//         count: number;
//         people: {
//             userId: string;
//             name: string;
//         }[];
//     }[];
//     dateStart: number;
//     dateEnd: number;
//     votes: {};
//     label: string;
// }[]

export const SuggestedTimeSchema = AvailLabelSchema.extend({
    availability: z.object({
        count: z.number(),
        people: z.object({
            userId: z.string(),
            name: z.string(),
        }).array(),
    }).array(),
    votes: z.record(AvailabilityModeSchema),
    totalParticipants: z.number(),
    freeAfter: z.object({
        count: z.number(),
        minutesFreeAfter: z.number()
    }).optional(),
})

export type SuggestedTime = z.infer<typeof SuggestedTimeSchema>
