'use client'
import { useCallback, useEffect, useRef, useState } from "react";
import { useCurrentState, useWindowSize } from "@planda/design-system";
import { remToPx } from "@planda/design-system";
import { MS_PER_DAY, MS_PER_MINUTE } from "@planda/design-system";
import { addDays, roundToNearestMinutes } from "date-fns";
import { range } from "lodash";
import { useSearchParams } from "next/navigation";
import { dateToIndices } from "@/services/utils";
import { HEIGHT_OF_TIMEGRID_CELL_IN_REM, HEIGHT_OF_TIMEGRID_CELL_IN_REM_MOBILE } from "./constants";
import { Mode, modeToColor } from './utils';
type Direction = 'top' | 'bottom' // | 'left' | 'topRight' | 'bottomRight' | 'bottomLeft' | 'topLeft' | 'right'
type Coordinates = { x: number, y: number }

/**
 * handles Rnd
 * @param events
 * @param firstDay REQUIRES: hours set to 0 (very start of day)
 * @param days number of days/columns in timegrid
 * @param cellHeight height of cell (should probably be a constant, but in rem so need conversion)
 * @returns
 */
export function useRnd(
    firstDay: Date,
    days: number,
    myOverlay: number[][],
    isDraggingRef: any,
    incrementInMinutes: number,
    selectedDragMode: Mode | 'auto',
    options?: {
        handleEdit?: (dates: number[], state: Mode) => void,
        cellHeight?: number,
        setIsDragging?: (isDragging: boolean) => void,
        bounds?: { startBlock: number, startOffsetMs: number, endBlock: number }
    }
    // handleDurationChange: (id: string, durationChange: number, newDateEnd: number) => void,
    // handleDateChange: (id: string, newDateStart: number, newDateEnd: number) => void,
) {
    let { handleEdit, cellHeight, setIsDragging, } = options || {}
    const { startBlock = 0, startOffsetMs = 0, endBlock } = options?.bounds || {}
    if (startBlock && !startOffsetMs) throw new Error("startBlock requires startOffset")

    const { isMobile } = useWindowSize('mobile')
    const DEFAULT_CELL_HEIGHT = remToPx(isMobile ? HEIGHT_OF_TIMEGRID_CELL_IN_REM_MOBILE : HEIGHT_OF_TIMEGRID_CELL_IN_REM)
    cellHeight = cellHeight || DEFAULT_CELL_HEIGHT
    const [cellWidth, setCellWidth, cellWidthRef] = useCurrentState(0) // causes timegrid to rerender

    const containerRef = useRef<any>(null)
    const searchParams = useSearchParams()
    const isEditing = true
    // const router = useRouter()
    // const { editing } = router.query
    // const isEditing = editing === 'true'

    const [invisibleHeight, setInvisibleHeight, invisibleHeightRef] = useCurrentState(0)
    const [invisibleWidth, setInvisibleWidth, invisibleWidthRef] = useCurrentState(cellWidth)

    const [isDraggingInvisible, setIsDraggingInvisible, isDraggingInvisibleRef] = useCurrentState<null | Coordinates & { origin: Coordinates }>(null)
    const [direction, setDirection] = useState<Direction>('bottom')

    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);
    useEffect(() => {
        if (!isEditing) {
            // console.log('not editing')
            return
        }

        const checkMouseButton = (e: TouchEvent | MouseEvent) => {
            return ('button' in e && e.button !== 0)
        }
        // console.log('editing')
        const onDragStart = (e: TouchEvent | MouseEvent, clientX: number, clientY: number) => {
            if (isDraggingRef.current || checkMouseButton(e)) {
                setIsDraggingInvisible(null)
                return
            }
            if (!containerRef.current || !containerRef.current.contains(e.target)) {
                setIsDraggingInvisible(null)
                return
            }
            e.preventDefault()
            document.getSelection()?.removeAllRanges();
            window.getSelection()?.removeAllRanges();
            const container = containerRef.current.getBoundingClientRect()

            const x = Math.floor((clientX - container.left) / cellWidthRef.current) * cellWidthRef.current
            const y = Math.round((clientY - container.top) / cellHeight!) * cellHeight!
            setIsDraggingInvisible({ x, y, origin: { x, y } })
            setInvisibleHeight(0)
            setInvisibleWidth(cellWidth)
        }

        const mouseDown = (e: MouseEvent) => {
            onDragStart(e, e.clientX, e.clientY)
        }

        const touchDown = (e: TouchEvent) => {
            onDragStart(e, e.touches[0].clientX, e.touches[0].clientY)
        }

        const dragMove = (e: MouseEvent | TouchEvent, clientX: number, clientY: number) => {
            // console.log('mouse moved')
            if (!isDraggingInvisibleRef.current || isDraggingRef.current || checkMouseButton(e)) {
                setIsDraggingInvisible(null)
                return
            }
            e.preventDefault()
            document.getSelection()?.removeAllRanges();
            window.getSelection()?.removeAllRanges();

            const origin = isDraggingInvisibleRef.current.origin
            const container = containerRef.current.getBoundingClientRect()
            const currentY = clientY - container.top
            const currentX = clientX - container.left

            const x = Math.floor(currentX / cellWidthRef.current) * cellWidthRef.current
            const y = currentY

            const height = Math.abs(currentY - isDraggingInvisibleRef.current.origin.y)
            if (y < origin.y) {
                // dragging up
                setDirection('top')
                setIsDraggingInvisible(p => (p && { ...p, y }))
            } else {
                // dragging down
                setDirection('bottom')
                setIsDraggingInvisible(p => (p && { ...p, y: p.origin.y }))
            }
            if (x < origin.x) {
                // dragging left
                setIsDraggingInvisible(p => (p && { ...p, x }))
            } else {
                // dragging right
                setIsDraggingInvisible(p => (p && { ...p, x: p.origin.x }))
            }
            setInvisibleWidth(cellWidthRef.current * Math.round(1 + Math.abs(x - origin.x) / cellWidthRef.current))
            setInvisibleHeight(height)
        }

        const mouseMove = (e: MouseEvent) => {
            dragMove(e, e.clientX, e.clientY)
        }

        const touchMove = (e: TouchEvent) => {
            dragMove(e, e.touches[0].clientX, e.touches[0].clientY)
        }

        const onDragEnd = (e: MouseEvent | TouchEvent) => {
            if (!isDraggingInvisibleRef.current) {
                setIsDraggingInvisible(null)
                return
            }
            e.preventDefault()
            // console.log(isDraggingRef.current.x, isDraggingRef.current.y, invisibleHeightRef.current)
            // TODO: pixelsToDate depends on firstDay, if first day changes, day will be wrong
            const dateStart = pixelsToDate(isDraggingInvisibleRef.current.x, isDraggingInvisibleRef.current.y)
            const days = Math.max(1, Math.round(invisibleWidthRef.current / cellWidthRef.current))

            const duration = pixelsToDuration(invisibleHeightRef.current)
            if (duration < incrementInMinutes * MS_PER_MINUTE) {
                setIsDraggingInvisible(null)
                // invalid drag
                return
            }
            const newMode = originToDragMode(isDraggingInvisibleRef.current.origin)
            const numBlocks = Math.round(duration / (MS_PER_MINUTE * incrementInMinutes))
            const { rowIndex, colIndex } = dateToIndices(dateStart.getTime(), firstDay.getTime())

            const dates = range(days).flatMap(day => range(numBlocks).map(i => {
                // TODO: filter out if not between start and end
                if (myOverlay[rowIndex + day][colIndex + i] === newMode) {
                    console.log('outside of overlay:', day, i)
                    return null
                }
                // technically don't need the end block stuff, since those blocks not even shown
                if (colIndex + i < startBlock || (endBlock !== undefined && colIndex + i > endBlock)) return null
                return dateStart.getTime() + i * (MS_PER_MINUTE * incrementInMinutes) + day * MS_PER_DAY
            })).filter(Boolean) as number[]
            // console.log("changed dates", numBlocks, dates.length, dates.map(x => new Date(x)), newMode)
            // console.log('numBlocks', numBlocks, dates.map(x => new Date(x)))
            // const dragMode = originToDragMode(isDraggingInvisibleRef.current.origin)
            // console.log("originToDragMode(isDraggingInvisibleRef.current.origin)", originToDragMode(isDraggingInvisibleRef.current.origin))

            handleEdit?.(dates, newMode)
            // const dateEnd = roundToNearestMinutes(dateStart + duration, { nearestTo: incrementInMinutes }).getTime()
            // handleDrag(dateStart, dateEnd)
            // handleDoubleClickDate?.({ dateStart, dateEnd }) // This function opens form
            setIsDraggingInvisible(null)
            setInvisibleHeight(0)
            setInvisibleWidth(cellWidth)
        }
        window.addEventListener("resize", forceUpdate);
        window.addEventListener("mousedown", mouseDown);
        window.addEventListener("touchstart", touchDown);
        window.addEventListener("mousemove", mouseMove);
        window.addEventListener("touchmove", touchMove);
        window.addEventListener("mouseup", onDragEnd);
        window.addEventListener("touchend", onDragEnd);
        // window.addEventListener("touchcancel", onDragEnd);
        // window.addEventListener("touchend touchcancel touchendoutside pointerup", onDragEnd);
        forceUpdate();
        return () => {
            window.removeEventListener("resize", forceUpdate);
            window.removeEventListener("mousedown", mouseDown);
            window.removeEventListener("touchstart", touchDown);
            window.removeEventListener("mousemove", mouseMove);
            window.removeEventListener("touchmove", touchMove);
            window.removeEventListener("mouseup", onDragEnd);
            window.removeEventListener("touchend", onDragEnd);
            // window.removeEventListener("touchcancel", onDragEnd);
            // window.removeEventListener("touchend touchcancel touchendoutside", onDragEnd);
        }
    }, [firstDay, cellHeight, isEditing, myOverlay, selectedDragMode, startBlock, startOffsetMs]);

    // ref from grid
    const dragAreaRef = (node: HTMLDivElement | null) => {
        containerRef.current = node
        setCellWidth(((node?.clientWidth || 0)) / days)
        // if (invisibleHeight === 0) setInvisibleHeight((node?.clientHeight || 0))
    }

    /**
     * helpers
     */
    function pixelsToDuration(px: number) {
        const actualCellHeight = cellHeight || DEFAULT_CELL_HEIGHT
        return Math.round(px * (MS_PER_MINUTE * incrementInMinutes) / actualCellHeight)
    }

    function pixelsToDate(x: number, y: number) {
        const day = Math.floor((x + 1) / cellWidthRef.current) // can't drag before cellWidth is loaded, so shouldn't ever be 0
        const ms = pixelsToDuration(y)
        return roundToNearestMinutes(addDays(firstDay, day).getTime() + ms + startOffsetMs, { nearestTo: incrementInMinutes })
    }

    // function pixelsToIndices(x: number, y: number) {
    //     const day = Math.floor((x + 1) / cellWidthRef.current) // can't drag before cellWidth is loaded, so shouldn't ever be 0
    //     return [day, Math.round(y / cellHeight!)]
    // }

    const originToMode = (origin: Coordinates) => {
        const i = Math.round(origin.x / cellWidthRef.current)
        const j = Math.round(origin.y / cellHeight! + startBlock)
        // const dateStart = pixelsToDate(origin.x, origin.y)
        // const duration = pixelsToDuration(invisibleHeightRef.current)
        // const dates = range(Math.round(duration / (MS_PER_MINUTE * incrementInMinutes))).map(i => {
        //     return dateStart.getTime() + i * (MS_PER_MINUTE * incrementInMinutes)
        // })
        // const isAvailable = dates.every(date => {
        //     const day = Math.floor((date - firstDay.getTime()) / MS_PER_MINUTE / 60 / 24)
        //     const order = Math.floor((date - firstDay.getTime()) / MS_PER_MINUTE / 15)
        //     return myOverlay[day][order] === 0
        // })
        return myOverlay[i][j] < 0 ? Mode.Available : (myOverlay[i][j] >= 2 ? Mode.Unavailable : (myOverlay[i][j] >= 1 ? Mode.Will_Move_If_Must : Mode.Neutral))
    }

    const originToDragMode = (origin: Coordinates) => {
        if (selectedDragMode !== 'auto') return selectedDragMode
        const mode = originToMode(origin)
        if (mode !== Mode.Neutral) return Mode.Neutral
        return Mode.Available
    }

    const originToColor = (origin: Coordinates) => {
        const dragMode = originToDragMode(origin)
        return modeToColor(dragMode)
        // return originToMode(origin) === Mode.Available ? 'green' : originToMode(origin) === Mode.Unavailable ? 'inherit' : 'red'
    }

    return {
        dragAreaRef,
        cellWidth,
        invisibleProps: {
            style: {
                position: 'absolute',

                top: isDraggingInvisible?.y || 0,
                left: isDraggingInvisible?.x || 0,
                width: invisibleWidth,
                height: invisibleHeight,
                // backgroundColor: 'var(--colors-overlay1)',
                // color depends on dragMode, dragMode
                backgroundColor: isDraggingInvisible ? originToColor(isDraggingInvisible.origin) : 'inherit',
                // dragMode === Mode.Available ? 'green' : dragMode === Mode.Unavailable ? 'inherit' : 'red',
            }
        }
    }
}
