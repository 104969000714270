'use client'

import { createContext, MutableRefObject, RefObject } from 'react'
import { Meeting, NewMeeting, Participant, SyncStatus, User } from '@/types'
import { ChatType } from './useChatHook'
import { Chat, ChatGPInstance, ChatMessageSimple, Persona } from '../../types/message'

const ChatContext = createContext<{
  chatRef: RefObject<ChatGPInstance>,
  isLoading?: boolean,
  user?: User,
  isSynced?: boolean,
  syncStatus?: SyncStatus,
  meeting?: Meeting,
  participant?: Participant,
  // meetings?: UserMeeting[],
  debug?: boolean
  personaPanelType: string
  // DefaultPersonas: Persona[]
  currentChatRef?: MutableRefObject<Chat | undefined>
  chatList: Chat[]
  personas: Persona[]
  isOpenPersonaModal?: boolean
  editPersona?: Persona
  personaModalLoading?: boolean
  openPersonaPanel?: boolean
  toggleSidebar?: boolean
  onOpenPersonaModal?: () => void
  onClosePersonaModal?: () => void
  setCurrentChat?: (chat: Chat) => void
  onCreatePersona?: (persona: Persona) => void
  onDeleteChat?: (chat: Chat) => void
  onDeletePersona?: (persona: Persona) => void
  onEditPersona?: (persona: Persona) => void
  onCreateChat?: (persona: Partial<NewMeeting>) => void
  onChangeChat?: (chat: Chat | string) => void
  saveMessages?: (messages: ChatMessageSimple[], type: ChatType) => void
  onOpenPersonaPanel?: (type?: string) => void
  onClosePersonaPanel?: () => void
  onToggleSidebar?: () => void
  forceUpdate?: () => void
}>({
  personaPanelType: 'chat',
  // DefaultPersonas: [],
  chatList: [],
  personas: [],
  chatRef: { current: null },
})

export default ChatContext
