import { z } from "zod";

export enum CalendarId {
    GOOGLE = 'google',
    MANUAL = 'manual',
    GOOGLE2 = 'google@placeholder',
}
export enum CalendarType {
    SYNC = 'sync',
    STORE_ONLY = 'store-only'
}

export const CalendarIdSchema = z.nativeEnum(CalendarId)
