import { useContext, useEffect, useImperativeHandle, useRef, useState } from "react"
import { useAuthSWR } from "@/lib/swr"
import { ChatContext, ChatMessage, ChatMessageSimple } from "."
import { ChatType } from "./useChatHook"

const useChat = (ref: any, type: ChatType) => {
    const conversationRef = useRef<ChatMessageSimple[]>()
    const textAreaRef = useRef<HTMLElement>(null)
    const bottomOfChatRef = useRef<HTMLDivElement>(null)
    const conversation = useRef<ChatMessageSimple[]>([])
    const { forceUpdate, saveMessages, isLoading: isLoadingChats, currentChatRef, user } = useContext(ChatContext)

    const currentMeetingId = currentChatRef?.current?.id
    const { data } = useAuthSWR<{ messages: ChatMessage[] }>(user?.userId, `/api/chat/${currentMeetingId || 'assistant'}/${type}`)

    const [isLoading, setIsLoading] = useState(false)

    const [message, setMessage] = useState('')

    const [currentMessage, setCurrentMessage] = useState<string>('')

    useEffect(() => {
        if (data?.messages) {
            conversation.current = data.messages
            forceUpdate?.()
        }
    }, [data])

    useImperativeHandle(ref, () => {
        return {
            setConversation(messages: ChatMessageSimple[]) {
                // conversation.current = messages
                conversation.current = data?.messages || messages
                forceUpdate?.()
            },
            getConversation() {
                return conversationRef.current
            },
            focus: () => {
                textAreaRef.current?.focus()
            }
        }
    }, [data])

    const clearMessages = () => {
        conversation.current = []
        forceUpdate?.()
    }

    useEffect(() => {
        if (bottomOfChatRef.current) {
            bottomOfChatRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [conversation, currentMessage, conversation.current.length])


    useEffect(() => {
        if (!conversation.current) return
        conversationRef.current = conversation.current
        if (currentChatRef?.current?.id && !isLoadingChats) {
            // TODO: do I need local storage? this saves to local, should I save assistant to local? technically it is part of individual chat but with no meetingID
            saveMessages?.(conversation.current, type)
        }
    }, [currentChatRef, conversation.current, saveMessages, isLoadingChats])

    return {
        textAreaRef,
        bottomOfChatRef,
        conversation,
        isLoading,
        setIsLoading,
        message,
        setMessage,
        currentMessage,
        setCurrentMessage,

        sendMessageProps: {
            textAreaRef,
            message,
            setMessage,
            isLoading,
            clearMessages,
            // onToggleSidebar
        }
    }
}

export default useChat